import { CDN_URL } from '@paladise/config/constants'

export const MOBILE_VIDEO_POSTER =
  CDN_URL + '/landing-page/landing_mobile_v1_1_1.jpg'
export const DESKTOP_VIDEO_POSTER =
  CDN_URL + '/landing-page/landing_desktop_v1_1.jpg'

export const VIDEOS = {
  tw: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_tw_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_tw_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_tw_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_tw_v1_1.mp4',
    },
  },
  en: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_en_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_en_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_en_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_en_v1_1.mp4',
    },
  },
  es: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_es_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_es_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_es_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_es_v1_1.mp4',
    },
  },
  fr: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_fr_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_fr_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_fr_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_fr_v1_1.mp4',
    },
  },
  jp: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_jp_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_jp_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_jp_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_jp_v1_1.mp4',
    },
  },
  kr: {
    MOBILE: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-mobile-h264/h264_landing_mobile_kr_v1_1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_mobile_kr_v1_2.mp4',
    },
    DESKTOP: {
      MANIFEST:
        CDN_URL +
        '/landing-page/landing-desktop-h264/h264_landing_desktop_kr_v1_1.m3u8',
      MP4_MANIFEST: CDN_URL + '/landing-page/landing_desktop_kr_v1_1.mp4',
    },
  },
} as const

export const LOCALE_VIDEO_MAP = {
  'zh-CN': 'tw',
  'zh-TW': 'tw',
  ja: 'jp',
  ko: 'kr',
  en: 'en',
  es: 'es',
  fr: 'fr',
} as const
