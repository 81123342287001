import { LANGUAGE } from 'config/i18n'
import { usePathname } from 'lib/navigation'
import { useLocale } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import React from 'react'

export const useLanguageSelect = () => {
  const defaultLocale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [locale, setLocale] = React.useState(defaultLocale)

  const handleLocalesChange = (locale: string) => {
    setLocale(locale)
    window.location.href =
      `/${locale}${pathname}` +
      (searchParams ? `?${searchParams.toString()}` : '')
  }

  const localeList = React.useMemo(() => {
    return LANGUAGE.map(child => ({
      name: child.key,
      title: child.name,
    })).sort((a, b) => {
      if (a.name === locale) {
        return -1
      }
      if (b.name === locale) {
        return 1
      }

      return 0
    })
  }, [locale])

  return {
    localeList,
    locale,
    handleLocalesChange,
  }
}
