'use client'

import { TRACKER_EVENTS } from '@paladise/tracker/constants/event'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@paladise/ui/components/ui/select'
import { cn } from '@paladise/ui/lib/utils'
import { useLanguageSelect } from 'hooks/useLanguageSelect'

interface ILanguageSelector {
  className?: string | undefined
}
const LanguageSelect = (props: ILanguageSelector) => {
  const { locale, handleLocalesChange, localeList } = useLanguageSelect()

  return (
    <Select value={locale} onValueChange={handleLocalesChange}>
      <SelectTrigger
        className={cn(
          'h-auto w-fit border-none bg-transparent p-0 text-current',
          props.className,
        )}
        data-tracker-click={TRACKER_EVENTS.textbar_language_click}
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent align="center" sideOffset={4}>
        {localeList.map(locale => (
          <SelectItem key={locale.name} value={locale.name}>
            {locale.title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

export default LanguageSelect
